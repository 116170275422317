exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artykul-contentful-article-slug-js": () => import("./../../../src/pages/artykul/{contentfulArticle.slug}.js" /* webpackChunkName: "component---src-pages-artykul-contentful-article-slug-js" */),
  "component---src-pages-blog-contentful-blog-post-slug-js": () => import("./../../../src/pages/blog/{contentfulBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inwestycje-contentful-mansion-slug-js": () => import("./../../../src/pages/inwestycje/{contentfulMansion.slug}.js" /* webpackChunkName: "component---src-pages-inwestycje-contentful-mansion-slug-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-contentful-offer-slug-drukuj-js": () => import("./../../../src/pages/oferta/{contentfulOffer.slug}/drukuj.js" /* webpackChunkName: "component---src-pages-oferta-contentful-offer-slug-drukuj-js" */),
  "component---src-pages-oferta-contentful-offer-slug-js": () => import("./../../../src/pages/oferta/{contentfulOffer.slug}.js" /* webpackChunkName: "component---src-pages-oferta-contentful-offer-slug-js" */),
  "component---src-pages-oferty-js": () => import("./../../../src/pages/oferty.js" /* webpackChunkName: "component---src-pages-oferty-js" */)
}

